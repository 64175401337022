import { ErrorHandler, NgModule, APP_INITIALIZER } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';

import { JwtModule } from '@auth0/angular-jwt';
import { AppComponent } from './app.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { PipesModule } from '@hrz/shared/pipes/pipes.module';
import { TranslateModule } from '@ngx-translate/core';
import { AppRoutingModule } from './app.routing';
import { AuthService } from '@hrz/core/services/auth.service';
import { SignalrWindow } from '@hrz/core/models/signalr/signalr-window';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { enGbLocale, itLocale, esLocale, plLocale } from 'ngx-bootstrap/locale';
import { CoreModule } from './core/core.module';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TranslateInitializeService } from './core/services/translate-initialize.service';
import { ErrorHandlerService } from '@hrz/core/services/error-handler.service';
import { ptBrLocale } from './core/utils/pt-br';
import { AccordionModule } from 'ngx-bootstrap/accordion';

defineLocale('it-it', itLocale);
defineLocale('en-gb', enGbLocale);
defineLocale('pt-pt', ptBrLocale);
defineLocale('es-es', esLocale);
defineLocale('el-gr', enGbLocale);
defineLocale('pl-pl', plLocale);
defineLocale('et-ee', enGbLocale);

// App Initializer - Keep the export keywork (important)
export function appInitializer(authService: AuthService, translateInitializeService: TranslateInitializeService): () => Promise<void> {
  return () => new Promise((resolve, _reject) => {
    authService.handleAuthentication().then(_ => {
      if (!authService.isAuthenticated()) {
        authService.login();
        resolve();
      } else {
        authService.scheduleRenewal();
        translateInitializeService.setAppTranslations().then(__ => {
          resolve();
        });
      }
    });
  });
}

// Token getter for HttpClient
export function httpClientTokenGetter() {
  return localStorage.getItem('access_token');
}

@NgModule({
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    AccordionModule.forRoot(),
    BsDropdownModule.forRoot(),
    TimepickerModule.forRoot(),
    ModalModule.forRoot(),
    BsDatepickerModule.forRoot(),
    TabsModule.forRoot(),
    PipesModule,
    TranslateModule.forRoot(),
    CollapseModule.forRoot(),
    CoreModule,
    HttpClientModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: httpClientTokenGetter,
        allowedDomains: [/.*horizons\.sg/, /.*azurewebsites\.net/],
        disallowedRoutes: [''],
      },
    }),
  ],
  declarations: [
    AppComponent
  ],
  // When you define the profiders here then these will be known as static.
  providers: [
    { provide: ErrorHandler, useClass: ErrorHandlerService },
    { provide: APP_INITIALIZER, useFactory: appInitializer, deps: [AuthService, TranslateInitializeService], multi: true },
    { provide: SignalrWindow, useValue: window },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
